@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;500&family=Staatliches&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, 
/* em, */
 img, ins, kbd, q, s, samp,
small, 
/* strike, 
strong,  */
sub, sup, tt, var,
/* b, u, i, */
 center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1.4;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4em;
  margin: 0;
  padding-left: 240px;
  background-color: #e6dcd3;
}

main {
  position: relative;
  /* height: 100vh; */
  background-color: #e6dcd3;
}
div:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Staatliches';
  font-weight: 400;
  line-height: 1.4em;
}
blockquote {
  border-left: 5px solid #eb4251 !important;
  margin: 10px !important;
  padding-left: 5px !important;
  font-style: normal !important;
}

h1 {
  font-size: xx-large;
}
h2 {
  font-size: x-large;
}
h3 {
  font-size: larger;
}
h4 {
  font-size: large;
}
h5 {
  font-size: medium;
}
h6 {
  font-size: small;
}
ul {
  font-family: 'Staatliches';
  font-size: 24;
  flex-direction: column;
}

ul a {
  color: #f2ebe6;
}
a {
  text-decoration: none;
  word-break: break-word;
}

.image img {
  max-width: 100%;
  width: 600px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  width: calc(100vw - 340px);

  padding: 75px 15px 0 0;
}

@media screen and (max-width: 1024px) {
  .my-masonry-grid {
    width: calc(100vw - 30px);
    margin-left: 0;
    margin-right: 30;
    padding: 75px 0 0 0;
  }
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}
#cloudbg {
  background: url(/static/media/Layer1-2.0f5b006e.png) repeat;
  animation: backgroundScrollleft 40s linear infinite;
  display: flex;
  flex: 1 1;
  margin: -20;
}

@media screen and (max-width: 1024px) {
  .bitSmaller {
    font-size: 1.6em;
  }
  #cloudbg {
    display: none;
  }
}

#cloud-scroll1 {
  background: url(/static/media/Layer2-2.5f598c45.png) repeat;
  animation: backgroundScroll 35s linear infinite;
}

#cloud-scroll2 {
  background: url(/static/media/Layer3.2f43ded6.png) repeat;
  animation: backgroundScroll 40s linear infinite;
}

#cloud-scroll3 {
  background: url(/static/media/Layer4.a688f5ac.png) repeat;
  animation: backgroundScroll 35s linear infinite;
}

#cloud-scroll4 {
  background: url(/static/media/Layer5.2f9e17b1.png) repeat;
  animation: backgroundScrollr 30s linear infinite;
}

#cloud-scroll5 {
  background: url(/static/media/Layer6.cf26206e.png) repeat;
  animation: backgroundScroll 25s linear infinite;
}

#cloud-scroll6 {
  background: url(/static/media/Layer7.060e4f65.png) repeat;
  animation: backgroundScrollr 20s linear infinite;
}

#cloud-scroll7 {
  background: url(/static/media/Layer8.9055d936.png) repeat;
  animation: backgroundScrolll 15s linear infinite;
}

@keyframes backgroundScroll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -768px;
  }
}

@keyframes backgroundScrolll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1024px -768px;
  }
}

@keyframes backgroundScrollr {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 768px;
  }
}

@keyframes backgroundScrollleft {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1024px 0;
  }
}
.banner-box {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 20px;
}

.fadeOutImg {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid green;
  font-family: 'Prompt', sans-serif;
  -webkit-text-fill-color: #f2ebe6;
  -webkit-box-shadow: 0 0 0px 1000px rgb(48, 48, 46) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input {
  font-family: 'Prompt', sans-serif;
  padding: 12;
  font-size: 16;
  display: block;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0);
}

button {
  font-family: 'Prompt', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #eb4251; */
  /* color: #f2ebe6; */
  padding: 4px;
  min-width: 100px;
  height: 50px;
  border: 0;
  font-weight: 500;
  font-size: 0.9em;
}
button:hover {
  background-color: #eb4251 !important;
}
.ck .ck-button__label {
  font-family: 'Prompt', sans-serif;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.ck-heading_spoiler {
  background-color: #30302e;
  color:#30302e
}

.ck-heading_spoiler:hover {
  background-color: transparent;

}

.menu {
  background: rgb(48, 48, 46);
  height: 100vh;
  width: 240px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  outline: none;
}
.menu .logo {
  padding: 2em 0.5em;
  text-align: center;
}
.menu .logo img {
  max-width: 100px;
  /* border-radius: 50%;
	overflow: hidden;
	border: 4px solid #ffea92;
	box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2); */
}

.menu ul {
  list-style: none;
  padding: 0.5em 0;
  margin: 0;
}
.menu ul li {
  padding: 1em 1em 1em 3em;
  font-size: 0.95em;
  font-weight: normal;
  background-repeat: no-repeat;
  background-position: left 25px center;
  /* background-size: auto 20px; */
  transition: all 0.15s linear;
  cursor: pointer;
}
.menu ul li {
  background-size: 32px 32px;
}
.menu ul li.icon-create-thread {
  background-image: url('/assets/fm-icons-Create_thread.svg');
}
.menu ul li.icon-slugs {
  background-image: url('/assets/fm-icons-Slugs.svg');
}
.menu ul li.icon-limbo {
  background-image: url('/assets/fm-icons-Limbo.svg');
}
.menu ul li.icon-users {
  background-image: url('/assets/fm-icons-Users.svg');
}
.menu ul li.icon-settings {
  background-image: url('/assets/fm-icons-Settings.svg');
}
.menu ul li.icon-admin {
  background-image: url('/assets/fm-icons-Admin_area.svg');
}
.menu ul li.icon-sign-out {
  background-image: url('/assets/fm-icons-Sign-Out.svg');
}
.menu ul li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.menu ul li:focus {
  outline: none;
}

@media screen and (max-width: 1024px) {
  body {
    padding-left: 0;
  }

  .menu {
    width: 230px;
    box-shadow: 0 0 0 100em rgba(0, 0, 0, 0);
    transform: translate3d(-230px, 0, 0);
    transition: all 0.4s ease-in-out;
  }
  .menu .smartphone-menu-trigger {
    width: 30px;
    height: 44px;
    position: absolute;
    top: 0;
    z-index: 9;
    left: 100%;
    background: #eb4251;
    background-image: url('/assets/fm-icons-Menu_open.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
  /* .menu .smartphone-menu-trigger:before,
  .menu .smartphone-menu-trigger:after {
    content: '';
    width: 50%;
    height: 2px;
    background: #fff;
    border-radius: 00px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  } */
  .menu .smartphone-menu-trigger:after {
    top: 55%;
    transform: translate3d(-50%, -50%, 0);
  }
  .menu ul li {
    /* padding: 1em 1em 1em 3em; */
    font-size: 0.9em;
  }
  .menu:focus {
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 0 100em rgba(0, 0, 0, 0.6);
  }
  .menu:focus .smartphone-menu-trigger {
    pointer-events: none;
  }
}

