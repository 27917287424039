
.menu {
  background: rgb(48, 48, 46);
  height: 100vh;
  width: 240px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  outline: none;
}
.menu .logo {
  padding: 2em 0.5em;
  text-align: center;
}
.menu .logo img {
  max-width: 100px;
  /* border-radius: 50%;
	overflow: hidden;
	border: 4px solid #ffea92;
	box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2); */
}

.menu ul {
  list-style: none;
  padding: 0.5em 0;
  margin: 0;
}
.menu ul li {
  padding: 1em 1em 1em 3em;
  font-size: 0.95em;
  font-weight: normal;
  background-repeat: no-repeat;
  background-position: left 25px center;
  /* background-size: auto 20px; */
  transition: all 0.15s linear;
  cursor: pointer;
}
.menu ul li {
  background-size: 32px 32px;
}
.menu ul li.icon-create-thread {
  background-image: url('/assets/fm-icons-Create_thread.svg');
}
.menu ul li.icon-slugs {
  background-image: url('/assets/fm-icons-Slugs.svg');
}
.menu ul li.icon-limbo {
  background-image: url('/assets/fm-icons-Limbo.svg');
}
.menu ul li.icon-users {
  background-image: url('/assets/fm-icons-Users.svg');
}
.menu ul li.icon-settings {
  background-image: url('/assets/fm-icons-Settings.svg');
}
.menu ul li.icon-admin {
  background-image: url('/assets/fm-icons-Admin_area.svg');
}
.menu ul li.icon-sign-out {
  background-image: url('/assets/fm-icons-Sign-Out.svg');
}
.menu ul li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.menu ul li:focus {
  outline: none;
}

@media screen and (max-width: 1024px) {
  body {
    padding-left: 0;
  }

  .menu {
    width: 230px;
    box-shadow: 0 0 0 100em rgba(0, 0, 0, 0);
    transform: translate3d(-230px, 0, 0);
    transition: all 0.4s ease-in-out;
  }
  .menu .smartphone-menu-trigger {
    width: 30px;
    height: 44px;
    position: absolute;
    top: 0;
    z-index: 9;
    left: 100%;
    background: #eb4251;
    background-image: url('/assets/fm-icons-Menu_open.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
  /* .menu .smartphone-menu-trigger:before,
  .menu .smartphone-menu-trigger:after {
    content: '';
    width: 50%;
    height: 2px;
    background: #fff;
    border-radius: 00px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  } */
  .menu .smartphone-menu-trigger:after {
    top: 55%;
    transform: translate3d(-50%, -50%, 0);
  }
  .menu ul li {
    /* padding: 1em 1em 1em 3em; */
    font-size: 0.9em;
  }
  .menu:focus {
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 0 100em rgba(0, 0, 0, 0.6);
  }
  .menu:focus .smartphone-menu-trigger {
    pointer-events: none;
  }
}
