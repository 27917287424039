@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;500&family=Staatliches&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4em;
  margin: 0;
  padding-left: 240px;
  background-color: #e6dcd3;
}

main {
  position: relative;
  /* height: 100vh; */
  background-color: #e6dcd3;
}
div:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Staatliches';
  font-weight: 400;
  line-height: 1.4em;
}
blockquote {
  border-left: 5px solid #eb4251 !important;
  margin: 10px !important;
  padding-left: 5px !important;
  font-style: normal !important;
}

h1 {
  font-size: xx-large;
}
h2 {
  font-size: x-large;
}
h3 {
  font-size: larger;
}
h4 {
  font-size: large;
}
h5 {
  font-size: medium;
}
h6 {
  font-size: small;
}
ul {
  font-family: 'Staatliches';
  font-size: 24;
  flex-direction: column;
}

ul a {
  color: #f2ebe6;
}
a {
  text-decoration: none;
  word-break: break-word;
}

.image img {
  max-width: 100%;
  width: 600px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  width: calc(100vw - 340px);

  padding: 75px 15px 0 0;
}

@media screen and (max-width: 1024px) {
  .my-masonry-grid {
    width: calc(100vw - 30px);
    margin-left: 0;
    margin-right: 30;
    padding: 75px 0 0 0;
  }
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}
#cloudbg {
  background: url(assets/img/Layer1-2.png) repeat;
  -webkit-animation: backgroundScrollleft 40s linear infinite;
  animation: backgroundScrollleft 40s linear infinite;
  display: flex;
  flex: 1;
  margin: -20;
}

@media screen and (max-width: 1024px) {
  .bitSmaller {
    font-size: 1.6em;
  }
  #cloudbg {
    display: none;
  }
}

#cloud-scroll1 {
  background: url(assets/img/Layer2-2.png) repeat;
  -webkit-animation: backgroundScroll 35s linear infinite;
  animation: backgroundScroll 35s linear infinite;
}

#cloud-scroll2 {
  background: url(assets/img/Layer3.png) repeat;
  -webkit-animation: backgroundScroll 40s linear infinite;
  animation: backgroundScroll 40s linear infinite;
}

#cloud-scroll3 {
  background: url(assets/img/Layer4.png) repeat;
  -webkit-animation: backgroundScroll 35s linear infinite;
  animation: backgroundScroll 35s linear infinite;
}

#cloud-scroll4 {
  background: url(assets/img/Layer5.png) repeat;
  -webkit-animation: backgroundScrollr 30s linear infinite;
  animation: backgroundScrollr 30s linear infinite;
}

#cloud-scroll5 {
  background: url(assets/img/Layer6.png) repeat;
  -webkit-animation: backgroundScroll 25s linear infinite;
  animation: backgroundScroll 25s linear infinite;
}

#cloud-scroll6 {
  background: url(assets/img/Layer7.png) repeat;
  -webkit-animation: backgroundScrollr 20s linear infinite;
  animation: backgroundScrollr 20s linear infinite;
}

#cloud-scroll7 {
  background: url(assets/img/Layer8.png) repeat;
  -webkit-animation: backgroundScrolll 15s linear infinite;
  animation: backgroundScrolll 15s linear infinite;
}

@-webkit-keyframes backgroundScroll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -768px;
  }
}

@keyframes backgroundScroll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -768px;
  }
}

@-webkit-keyframes backgroundScrolll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1024px -768px;
  }
}

@keyframes backgroundScrolll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1024px -768px;
  }
}

@-webkit-keyframes backgroundScrollr {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 768px;
  }
}

@keyframes backgroundScrollr {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 768px;
  }
}

@-webkit-keyframes backgroundScrollleft {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1024px 0;
  }
}

@keyframes backgroundScrollleft {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1024px 0;
  }
}
.banner-box {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 20px;
}

.fadeOutImg {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid green;
  font-family: 'Prompt', sans-serif;
  -webkit-text-fill-color: #f2ebe6;
  -webkit-box-shadow: 0 0 0px 1000px rgb(48, 48, 46) inset;
  transition: background-color 5000s ease-in-out 0s;
}

input {
  font-family: 'Prompt', sans-serif;
  padding: 12;
  font-size: 16;
  display: block;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0);
}

button {
  font-family: 'Prompt', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #eb4251; */
  /* color: #f2ebe6; */
  padding: 4px;
  min-width: 100px;
  height: 50px;
  border: 0;
  font-weight: 500;
  font-size: 0.9em;
}
button:hover {
  background-color: #eb4251 !important;
}
.ck .ck-button__label {
  font-family: 'Prompt', sans-serif;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.ck-heading_spoiler {
  background-color: #30302e;
  color:#30302e
}

.ck-heading_spoiler:hover {
  background-color: transparent;

}